import Navigation from '../navigation'
import Footer from '../footer'
import { Container, Row, Col } from 'react-bootstrap'
import '../../../src/global.css'
import BreadCrumbs from '../bread-crumbs'
import { Helmet } from 'react-helmet'

function HowToBuy() {
  const items = [
    {
      link: '/',
      text: 'HOME',
    },
    {
      link: '/how-to-buy',
      text: 'HOW TO BUY',
    },
  ]

  return (
    <div>
      <Helmet>
        <title>How To Buy</title>
        <meta name="description" content="how to buy" />
        <meta name="keywords" content="how to buy" />
        <link rel="canonical" href="https://m5controlsystems.com/how-to-buy" />
      </Helmet>
      <Container style={{ paddingLeft: '12px', margin: '0px' }}>
        <Row>
          <Col>
            <Navigation />
          </Col>
        </Row>
      </Container>

      <BreadCrumbs props={items} />

      <Container className="m5controlsystems-content">
        <Row>
          <Col>
            <h3 id="m5controlsystems-heading">How To Buy</h3>
            <p id="m5controlsystems-text">
              At M5 Control Systems, we’re passionate about designing and crafting high-quality electronic modules
              to support innovators, hobbyists, and engineers. In the past, we sold our products on Amazon, but due
              to Amazon’s restrictive policies and our commitment to creating the best experience for our customers,
              we now sell exclusively through our website.
              <br></br><br></br>
              By buying directly from us, you’re helping support a company focused on quality, innovation, and
              personalized service. Every purchase allows us to prioritize product improvements, customer feedback,
               and new developments without the limitations of third-party platforms.
              <br></br><br></br>
              Thank you for choosing to shop with us directly – we’re excited to be part of your next project!
            </p>
          </Col>
        </Row>
        <Row>
          <Footer />
        </Row>
      </Container>
    </div>
  )
}

export default HowToBuy
