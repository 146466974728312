import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { LinkContainer } from 'react-router-bootstrap'
import './index.css'

function BreadCrumbs({ props }) {
  return (
    <Breadcrumb>
      {props.map((item, index) => (
        <LinkContainer to={item.link} key={index}>
          <Breadcrumb.Item
            className={index === props.length - 1 ? 'text-white' : ''}
          >
            {item.text}
          </Breadcrumb.Item>
        </LinkContainer>
      ))}
    </Breadcrumb>
  )
}

export default BreadCrumbs
