import { createAsyncThunk } from '@reduxjs/toolkit'

export const getCategories = createAsyncThunk(
  'api/getCategories',
  async (arg, rejectWithValue) => {
    try {
      // Making multiple fetch calls using async/await
      const [response1, response2] = await Promise.all([
        fetch('/products.json'),
        fetch('/categories.json'),
      ])

      // Check responses and handle errors
      if (!response1.ok || !response2.ok) {
        return rejectWithValue('Failed to fetch data')
      } 

      // Convert responses to JSON
      const products = await response1.json()
      const categories = await response2.json()

      // Add products to categories
      categories.forEach(category => {
        category.products = products.filter(
          product => product.categoryId === category.id
        )
      })

      // Return all data as a single object
      return { categories }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
