import { useEffect } from 'react'
import Navigation from '../navigation'
import { Link } from 'react-router-dom'
import Footer from '../footer'
import { Container, Row, Col, Button } from 'react-bootstrap'
import '../../../src/global.css'
import BreadCrumbs from '../bread-crumbs'
import Card from 'react-bootstrap/Card'
import './index.css'
import { useSelector, useDispatch } from 'react-redux'
import { getCategories } from '../../app/api'
import { Helmet } from 'react-helmet'

function Categories() {
  const { categories, loading, initialized } = useSelector(
    state => state.categoriesSlice
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (!initialized) {
      dispatch(getCategories())
    }
  }, [dispatch, initialized])

  const items = [
    {
      link: '/',
      text: 'HOME',
    },
    {
      link: '/',
      text: 'PRODUCTS',
    },
  ]

  if (loading) {
    return <div></div>
  }

  return (
    <div>
      <Helmet>
        <title>M5 Control Systems</title>
        <meta name="description" content="m5 control systems" />
        <meta name="keywords" content="m5 control systems" />
        <link rel="canonical" href="https://m5controlsystems.com" />
      </Helmet>
      <Container style={{ paddingLeft: '12px', margin: '0px' }}>
        <Row>
          <Col>
            <Navigation />
          </Col>
        </Row>
      </Container>

      <BreadCrumbs props={items} />

      <Container className="m5controlsystems-content">
        <div>
          <h3 id="m5controlsystems-heading">Welcome to M5 Control Systems</h3>
          <p>
            We specialize in reliable, high-performance electronic modules for engineers, hobbyists, and innovators.
            Explore our selection of DC-DC converters, battery management systems, and more – crafted to make your
            projects easier, faster, and more efficient.
          </p>
        </div>
        <Row>
          {categories?.map((category, index) => (
            <Col key={index} sm={6} md={4} lg={4}>
              <Card
                className="h-100 no-border text-center"
                style={{ paddingBottom: '12px' }}
              >
                <Link to={`/products/${category.partialURL}`}>
                  <Card.Img src={category.image} className="card-img-custom" />
                </Link>
                <Card.Body className="d-flex flex-column">
                  <Card.Title>{category.name}</Card.Title>
                  <Card.Text>{category.description}</Card.Text>
                  <div className="mt-auto d-flex justify-content-center">
                    <Button
                      as={Link}
                      to={`/products/${category.partialURL}`}
                      className="mt-auto fixed-width-button"
                    >
                      LEARN MORE
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <Row>
          <Footer />
        </Row>
      </Container>
    </div>
  )
}

export default Categories
