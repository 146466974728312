import { React, useState, useEffect } from 'react'
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import logo from '../../images/logos/logo.png'
import './index.css'
import { useSelector, useDispatch } from 'react-redux'
import { getCategories } from '../../app/api'

function Navigation() {
  const [isOpen, setIsOpen] = useState(false)

  const toggleNavbar = () => {
    setIsOpen(!isOpen)
  }

  const { categories, loading, initialized } = useSelector(
    state => state.categoriesSlice
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (!initialized) {
      dispatch(getCategories())
    }
  }, [dispatch, initialized])

  if (loading) {
    return <div></div>
  }

  return (
    <Navbar expand="lg">
      {/* todo: proper styles */}
      <Container id="full-width-container" style={{ paddingLeft: '0px' }}>
        <Navbar.Brand as={Link} to="/">
          <img src={logo} alt="logo" width="350px" />
        </Navbar.Brand>
        <Navbar.Toggle onClick={toggleNavbar} />
        <Navbar.Collapse>
          <Nav className="me-auto">
            <NavDropdown title="PRODUCTS">
              <NavDropdown.Item as={Link} to="/">
                All Categories
              </NavDropdown.Item>

              {categories?.map((category, index) => (
                <NavDropdown.Item
                  key={index}
                  as={Link}
                  to={`/products/${category.partialURL}`}
                >
                  {category.name}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
            <Nav.Link as={Link} to="/about">
              ABOUT
            </Nav.Link>
            <Nav.Link as={Link} to="/how-to-buy">
              HOW TO BUY
            </Nav.Link>
            <Nav.Link style={{ marginRight: '25px' }} as={Link} to="/contact">
              CONTACT
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Navigation
