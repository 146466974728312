import { useState, useEffect, useRef } from 'react'
import Navigation from '../navigation'
import { Container, Row, Col, Card } from 'react-bootstrap'
import '../../../src/global.css'
import './index.css'
import BreadCrumbs from '../bread-crumbs'
import { useSelector, useDispatch } from 'react-redux'
import { getCategories } from '../../app/api'
import Carousel from 'react-bootstrap/Carousel'
import { formatter } from '../../utils/index'
import { findProductById } from '../../utils'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet'

function Product({ props }) {
  const [partialURL] = useState(useParams().category)
  const [category, setCategory] = useState('')
  const [productId] = useState(useParams().productId)

  const buyButtonContainer = useRef(null);

  const { categories, loading, initialized } = useSelector(
    state => state.categoriesSlice
  )

  useEffect(() => {
    setCategory(categories.find(x => x.partialURL === partialURL))
  }, [categories, partialURL])

  const dispatch = useDispatch()
  const [product, setProduct] = useState()
  const [productName, setProductName] = useState('')

  // boostrap carousel
  const [index, setIndex] = useState(0)
  const handleSelect = selectedIndex => {
    setIndex(selectedIndex)
  }

  const handleClick = () => {
    if (index < 3) {
      setIndex(index + 1)
    } else {
      setIndex(0)
    }
  }

  useEffect(() => {
    if (!initialized) {
      dispatch(getCategories())
    }
  }, [dispatch, initialized])

  useEffect(() => {
    setProduct(findProductById(categories, productId))
  }, [categories, productId])

  useEffect(() => {
    if (product && product['data-item-name']) {
      setProductName(String(product['data-item-name']).toUpperCase())
    }
  }, [product])

  const items = [
    {
      link: '/',
      text: 'HOME',
    },
    {
      link: '/',
      text: 'PRODUCTS',
    },
    {
      link: `/products/${category?.partialURL}`,
      text: category?.name?.toUpperCase(),
    },
    {
      link: '',
      text: `${productName}`,
    },
  ]

  if (loading) {
    return <div></div>
  }

  return (
    <div>
      <Helmet>
        <title>{product?.meta.title}</title>
        <meta name="description" content={product?.meta.description} />
        <meta name="keywords" content={product?.meta.keywords} />
        <link rel="canonical" href={product?.canonicalUrl} />

      </Helmet>
      <Container style={{ paddingLeft: '12px', margin: '0px' }}>
        <Row>
          <Col>
            <Navigation />
          </Col>
        </Row>
      </Container>

      <BreadCrumbs props={items} />

      <Container className="m5controlsystems-content">
        <Row>
          <Col md="6">
            <Row>
              <Col>
                <Carousel
                  touch={true}
                  onClick={() => handleClick()}
                  interval={null}
                  activeIndex={index}
                  fade={true}
                  indicators={false}
                  controls={false}
                >
                  <Carousel.Item>
                    <img className="carousel-image" src={product?.p1} alt="" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img className="carousel-image" src={product?.p2} alt="" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img className="carousel-image" src={product?.p3} alt="" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img className="carousel-image" src={product?.p4} alt="" />
                  </Carousel.Item>
                </Carousel>
              </Col>
            </Row>
            <Row xs={1} md={4} className="g-4" style={{ paddingTop: '20px' }}>
              {Array.from({ length: 4 }).map((_, idx) => (
                <Col key={idx} className="d-none d-md-block">
                  {idx === index ? (
                    <Card
                      className="card-selected"
                      onClick={() => handleSelect(idx)}
                    >
                      <Card.Img
                        variant="top"
                        src={product?.[`p${idx + 1}`]}
                        id="m5-card-image"
                      />
                    </Card>
                  ) : (
                    <Card
                      className="card-unselected"
                      onClick={() => handleSelect(idx)}
                    >
                      <Card.Img
                        variant="top"
                        src={product?.[`p${idx + 1}`]}
                        id="m5-card-image"
                      />
                    </Card>
                  )}
                </Col>
              ))}
            </Row>
          </Col>
          <Col>
            <h2 className="m5controlsystems-heading">{productName}</h2>
            <div className="product-id">
              PRODUCT ID: {product?.id.toUpperCase()}
            </div>
            <div className="product-price">
              {product && formatter.format(product?.price)}
            </div>
            {/* <div className="quantity">
              <Form.Label htmlFor="quantityInput">QUANTITY</Form.Label>
              <Form.Control
                id="quantityInput"
                type="number"
                value={quantity}
                onChange={handleQuantityChange}
                min="1" // Minimum value
              />
            </div> */}
            <div id="m5-5v-2a-01" ref={buyButtonContainer}></div>
          </Col>
        </Row>
        <br></br>
        {product?.headings.map(heading => (
          <Row key={heading.text}>
            <Col>
              <h3 className="m5controlsystems-heading">{heading.text}</h3>
              {heading.text === 'Description' && (
                <p className="m5controlsystems-text">
                  {product?.['content-description']}
                </p>
              )}
              <ul
                className="m5controlsystems-text"
                style={{ listStyleType: 'square' }}
              >
                {heading.bulletPoints?.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
              {heading.text === 'Downloads' && (
                heading.downloads?.map((item, index) => (
                  <a
                    key={index} // Added the key here
                    href={`${process.env.PUBLIC_URL}${item.url}`}
                    download={item.text}
                  >
                    <button className="btn btn-primary text-left">
                      <FontAwesomeIcon icon={faDownload} /> {item.text}
                    </button>
                  </a>
                ))
              )}
            </Col>
          </Row>
        ))}
      </Container>
      <br></br>
    </div>
  )
}

export default Product
