// Create a new formatter for US dollars
export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  // You can use these options to control the display of the currency
  minimumFractionDigits: 2, // This is the default when currency is set
  maximumFractionDigits: 2, // This is the default when currency is set
})

export const findProductById = (categories, id) => {
  for (const category of categories) {
    for (const product of category.products) {
      if (product.id.toLowerCase() === id.toLowerCase()) {
        return product
      }
    }
  }

  return null
}
