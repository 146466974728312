import Navigation from '../navigation'
import Footer from '../footer'
import { Container, Row, Col } from 'react-bootstrap'
import '../../../src/global.css'
import BreadCrumbs from '../bread-crumbs'
import { Helmet } from 'react-helmet'

function About() {
  const items = [
    {
      link: '/',
      text: 'HOME',
    },
    {
      link: '/about',
      text: 'ABOUT',
    },
  ]

  return (
    <div>
      <Helmet>
        <title>About</title>
        <meta name="description" content="about" />
        <meta name="keywords" content="about" />
        <link rel="canonical" href="https://m5controlsystems.com/about" />
      </Helmet>
      <Container style={{ paddingLeft: '12px', margin: '0px' }}>
        <Row>
          <Col>
            <Navigation />
          </Col>
        </Row>
      </Container>

      <BreadCrumbs props={items} />

      <Container className="m5controlsystems-content">
        <Row>
          <Col>
            <h3 id="m5controlsystems-heading">Who We Are</h3>
            <p id="m5controlsystems-text">
              M5 Control Systems is dedicated to empowering engineers, students, and hobbyists with reliable, 
              prebuilt electronic modules that streamline prototyping and accelerate product development. 
              Our modules are designed for seamless integration into final products or as effective aids during
              the prototyping phase, helping you take projects from concept to completion efficiently.
            </p>
            <h3 id="m5controlsystems-heading">Our Advantage</h3>
            <p id="m5controlsystems-text">
              The rapid evolution of integrated circuits has transformed the electronics landscape, 
              enabling focused, efficient designs by offloading complex functions. 
              At M5 Control Systems, we harness this potential, combining top-tier integrated circuits, 
              microcontrollers, voltage regulators, and other essential components into purpose-driven modules. 
              Our products are built for high performance, ease of use, and quick mastery, reducing the learning 
              curve and making sophisticated solutions accessible to engineers and innovators alike.
            </p>
            <h3 id="m5controlsystems-heading">Our Focus</h3>
            <p id="m5controlsystems-text">
              We are committed to delivering quality products at reasonable prices. Every module is crafted using 
              components from trusted, reputable manufacturers, ensuring reliability and longevity. We also prioritize
              documentation, including comprehensive guides and links to essential datasheets, making it easy to
              understand and replicate our designs if you choose to integrate similar features in your final products.
            </p>
            <h3 id="m5controlsystems-heading">Our Invitation</h3>
            <p id="m5controlsystems-text">
              Whether you’re prototyping a new idea or enhancing a final design, our products can help make your
              vision a reality. Explore our range of modules and discover how M5 Control Systems can be an essential
              part of your next project.
            </p>
          </Col>
        </Row>
        <Row>
          <Footer />
        </Row>
      </Container>
    </div>
  )
}

export default About
