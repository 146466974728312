import { createSlice } from '@reduxjs/toolkit'
import { getCategories } from './api'

const initialState = {
  categories: [],
  initialized: false,
  loading: false,
  error: null,
}

export const categoriesSlice = createSlice({
  name: 'state',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCategories.pending, state => {
        state.loading = true
        state.initialized = false
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.loading = false
        state.categories = action.payload.categories
        state.initialized = true
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.loading = false
        state.initialized = false
        state.error = action.payload
      })
  },
})

// Action creators are generated for each case reducer function
export const { addCategories } = categoriesSlice.actions

export default categoriesSlice.reducer
