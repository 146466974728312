import { useEffect, useState } from 'react'
import Navigation from '../navigation'
import { Link, useParams, useNavigate } from 'react-router-dom'
import Footer from '../footer'
import { Container, Row, Col, Button } from 'react-bootstrap'
import '../../../src/global.css'
import BreadCrumbs from '../bread-crumbs'
import Card from 'react-bootstrap/Card'
import './index.css'
import { useSelector, useDispatch } from 'react-redux'
import { getCategories } from '../../app/api'
import { Helmet } from 'react-helmet'

function Products(props) {
  const partialURL = useParams().category
  const navigate = useNavigate();

  const { categories, loading, initialized } = useSelector(
    state => state.categoriesSlice
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (!initialized) {
      dispatch(getCategories())
    }
  }, [dispatch, initialized])

  const [category, setCategory] = useState({})
  const [items, setItems] = useState([])

  useEffect(() => {
    const category = categories.find(category => category.partialURL === partialURL)

    if (category) {
      setCategory(category)
    }
  }, [categories, partialURL, navigate])

  useEffect(() => {
    setItems([
      {
        link: '/',
        text: 'HOME',
      },
      {
        link: '/',
        text: 'PRODUCTS',
      },
      {
        link: `/products/${category?.partialURL}`,
        text: category?.name?.toUpperCase(),
      },
    ])
  }, [category])

  if (loading) {
    return <div></div>
  }

  return (
    <div>
      <Helmet>
        <title>{category?.meta?.title}</title>
        <meta name="description" content={category?.meta?.description} />
        <meta name="keywords" content={category?.meta?.keywords} />
        <link rel="canonical" href={category?.canonicalUrl} />
      </Helmet>
      <Container style={{ paddingLeft: '12px', margin: '0px' }}>
        <Row>
          <Col>
            <Navigation />
          </Col>
        </Row>
      </Container>

      <BreadCrumbs props={items} />

      <Container className="m5controlsystems-content">
        <Row xs={1} md={4} className="g-4 justify-content-center">
          {category?.products?.map((product, index) => (
            <Col key={index} md={3}>
              <Card className="h-100 no-border shadow">
                <Link to={`/products/${category?.partialURL}/${product.id.toLowerCase()}`}>
                  <Card.Img variant="top" src={product.p1} />
                </Link>
                <Card.Body className="d-flex flex-column">
                  <Card.Title>{product['data-item-name']}</Card.Title>
                  <Card.Text className="mb-2 text-muted">
                    PRODUCT ID: {product.id.toUpperCase()} <br></br>
                    <b>${product['price']}</b>
                  </Card.Text>
                  <Card.Text>{product['data-item-description']}</Card.Text>
                  <div className="mt-auto d-flex justify-content-center">
                    <Button
                      as={Link}
                      to={`/products/${category?.partialURL}/${product.id.toLowerCase()}`}
                      className="mt-auto"
                      style={{ width: '100%' }}
                    >
                      LEARN MORE
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <Row>
          <Footer />
        </Row>
      </Container>
    </div>
  )
}

export default Products
