import Navigation from '../navigation'
import Footer from '../footer'
import { Container, Row, Col } from 'react-bootstrap'
import '../../../src/global.css'
import BreadCrumbs from '../bread-crumbs'
import { Helmet } from 'react-helmet'

function Contact() {
  const items = [
    {
      link: '/',
      text: 'HOME',
    },
    {
      link: '/contact',
      text: 'CONTACT',
    },
  ]

  return (
    <div>
      <Helmet>
        <title>Contact</title>
        <meta name="description" content="contact" />
        <meta name="keywords" content="contact" />
        <link rel="canonical" href="https://m5controlsystems.com/contact" />
      </Helmet>
      <Container style={{ paddingLeft: '12px', margin: '0px' }}>
        <Row>
          <Col>
            <Navigation />
          </Col>
        </Row>
      </Container>

      <BreadCrumbs props={items} />

      <Container className="m5controlsystems-content">
        <Row>
          <Col>
            <h3 id="m5controlsystems-heading">Contact Us</h3>
            <p id="m5controlsystems-text">
              We’d love to hear from you! Whether you have questions about our current products, are curious about
              future releases, need technical support, or want to share feedback on how we can improve,
              feel free to reach out.
              <br />
              <br />
              <a href="mailto:sidney.kantor@m5controlsystems.com">
                <b>sidney.kantor@m5controlsystems.com</b>
              </a>
              <br />
              <br />
              Your thoughts and inquiries help us continually enhance our products and services.
              We look forward to connecting with you!
            </p>
          </Col>
        </Row>
        <Row>
          <Footer />
        </Row>
      </Container>
    </div>
  )
}

export default Contact
