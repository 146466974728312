import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  count: 0,
  total: 0,
}

export const cartSlice = createSlice({
  name: 'state',
  initialState,
  reducers: {
    setCount: (state, action) => {
      state.count = action.payload
    },
    setTotal: (state, action) => {
      state.total = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setCount, setTotal } = cartSlice.actions

export default cartSlice.reducer
