import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

function RedirectToCategories() {
  const { productId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  console.log('location', location);

  useEffect(() => {
    // Redirect to the new URL format
    navigate(`/`, { replace: true });
  }, [productId, navigate]);

  return null; // Render nothing as we're redirecting
}

export default RedirectToCategories;