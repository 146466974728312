import React from 'react'
import './index.css'

function Footer() {
  return (
    <div id="m5controlsystems-footer">
      Copyright &copy; 2024 by M5 Control Systems, LLC. All Rights Reserved.
    </div>
  )
}

export default Footer
